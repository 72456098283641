import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import Select from 'react-select';
import PoyikaImages from '../../components/poyika-images';
import Whatsapp from '../../assets/images/whatsapp.png';
import Whatsapp1 from '../../assets/images/whatsapp-1.png';
import Call from '../../assets/images/call.png';
import { LuClock, LuSearch, AiOutlineAppstore, AiOutlineStar } from '../../assets/icons/vander';

const options = [
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
];

const buyOptions = [
  { value: '9AM - 12PM', label: '9AM - 12PM' },
  { value: '1PM - 5PM', label: '1PM - 5PM' },
  { value: '6PM - 10PM', label: '6PM - 10PM' },
];

const rateOptions = [
  { value: 'Econamy', label: 'Econamy' },
  { value: 'Delux', label: 'Delux' },
  { value: 'Premium', label: 'Premium' },
];

const customStyles = {
  control: (styles) => ({
    ...styles,
    color: 'rgb(165 124 1 / var(--tw-bg-opacity)) !important',
    placeholder: {
      color: 'black',
    },
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'white',
    color: 'black',
    zIndex: isFocused ? 999 : 'auto',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'black',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // Set the desired z-index value
  }),
};

export default function ExploreOne() {
  useEffect(() => {
    document.documentElement.classList.add('dark');
  }, []);
  const [currentButton, setCurrentButton] = useState(0);
    useEffect(() => {
        console.log(currentButton);
        const intervalId = setInterval(() => {
          if (currentButton === 0) {
            document.querySelector('.call-button').style.animation = 'shake 0.4s';
          } else {
            document.querySelector('.whatsapp-button').style.animation = 'shake 0.4s';
          }
    
          setTimeout(() => {
            if (currentButton === 0) {
              document.querySelector('.call-button').style.animation = '';
              setCurrentButton(1); // Switch to the WhatsApp button
            } else {
              document.querySelector('.whatsapp-button').style.animation = '';
              setCurrentButton(0)
            }
          }, currentButton === 0 ? 500 : 500); // 4 seconds for call button, 0 seconds for WhatsApp button
        }, 500); // Faster interval
    
        return () => clearInterval(intervalId);
      }, [currentButton]);
    
      const makeCall = () => {
        // Add your call functionality here
        console.log('Making a call...');
        window.location.href = 'tel:+09446771328';
      };
    
      const openWhatsApp = () => {
        // Add your WhatsApp functionality here
        console.log('Opening WhatsApp...');
        window.location.href = "https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details";
      };
    


  const daysContainerRef = useRef(null);
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);
  const monthYearRef = useRef(null);
  const dateInputRef = useRef(null);
  const calendarRef = useRef(null);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    renderCalendar();
  }, [currentDate, selectedDate]);

  const handleDayClick = (day) => {
    const newSelectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    setSelectedDate(newSelectedDate);
  };

  const createDayElement = (day) => {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    const dayElement = document.createElement('div');
    dayElement.classList.add('day');

    if (date.toDateString() === new Date().toDateString()) {
      dayElement.classList.add('current');
    }
    if (selectedDate && date.toDateString() === selectedDate.toDateString()) {
      dayElement.classList.add('selected');
    }

    dayElement.textContent = day;
    dayElement.addEventListener('click', () => {
      handleDayClick(day);
    });
    daysContainerRef.current.appendChild(dayElement);
  };

  const renderCalendar = () => {
    daysContainerRef.current.innerHTML = '';
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    monthYearRef.current.textContent = `${currentDate.toLocaleString('default', {
      month: 'long',
    })} ${currentDate.getFullYear()}`;

    for (let day = 1; day <= lastDay.getDate(); day++) {
      createDayElement(day);
    }
  };

  const positionCalendar = () => {
    const inputRect = dateInputRef.current.getBoundingClientRect();
    calendarRef.current.style.top = `${inputRect.bottom - 200}px`;
    calendarRef.current.style.left = `${inputRect.left - 200}px`;
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, prevDate.getDate()));
  };
  
  const handleNextClick = (e) => {
    e.preventDefault();
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, prevDate.getDate()));
  };
  


  const handleInputClick = () => {
    calendarRef.current.style.display = 'block';
  };

  const handleDocumentClick = (event) => {
    if (!dateInputRef.current.contains(event.target) && !calendarRef.current.contains(event.target)) {
      calendarRef.current.style.display = 'none';
    }
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const prevBtn = prevBtnRef.current;
    const nextBtn = nextBtnRef.current;
    const dateInput = dateInputRef.current;
    const documentClickHandler = handleDocumentClick;

    prevBtn.addEventListener('click', handlePrevClick);
    nextBtn.addEventListener('click', handleNextClick);
    dateInput.addEventListener('click', handleInputClick);
    document.addEventListener('click', documentClickHandler);

    return () => {
      if (prevBtn) prevBtn.removeEventListener('click', handlePrevClick);
      if (nextBtn) nextBtn.removeEventListener('click', handleNextClick);
      if (dateInput) dateInput.removeEventListener('click', handleInputClick);
      if (documentClickHandler) document.removeEventListener('click', documentClickHandler);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', positionCalendar);

    return () => {
      window.removeEventListener('resize', positionCalendar);
    };
  }, []);

  
  

    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg10.jpg')] bg-bottom bg-no-repeat bg-cover bg-center p-40">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span className="bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text">Tour </span> Gallery</h3>

                    </div>
                </div>

            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:pb-24 pb-16 -mt-16">
                <div className="container z-1">
                    <div className="grid grid-cols-1">
                        <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow dark:shadow-gray-700">
                            <div className="registration-form text-dark text-start">
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                    {/* <div>
                                        <label className="form-label font-medium dark:text-white">Search:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <LuSearch className="icons"/>
                                            <input name="name" type="text" id="job-keyword" className="form-input w-full filter-input-box bg-gray-50 dark:bg-slate-800 border-0 focus:ring-transparent" placeholder="Search your keaywords" />
                                        </div>
                                    </div> */}

                                    <div>
                                        <label htmlFor="choices-catagory" className="form-label font-medium dark:text-white">Calender:</label>
                                        <div className="filter-search-form relative  mt-5" style={{marginLeft:'40px'}}>
                                        
                                        <input
                                          type="text"
                                          id="dateInput"
                                          placeholder="Select...."
                                          ref={dateInputRef}
                                          value={selectedDate ? formatDate(selectedDate) : ''}
                                         />

                                        <div className="calendar" id="calendar" ref={calendarRef}>
                                          <div className="header">
                                            <button id="prevBtn" ref={prevBtnRef}>
                                              &lt;
                                            </button>
                                            <h2 id="monthYear" ref={monthYearRef}>
                                              Month Year
                                            </h2>
                                            <button id="nextBtn" ref={nextBtnRef}>
                                              &gt;
                                            </button>
                                          </div>
                                          <div className="days" id="daysContainer" ref={daysContainerRef}></div>
                                        </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-min-price" className="form-label font-medium dark:text-white">Time:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <LuClock className="icons text-base"/>
                                            <Select 
                                            menuPortalTarget={document.body} // Specify the target for the menu portal
                                            menuPosition="fixed" // Use fixed positioning for the menu
                                             styles={customStyles} className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={buyOptions} />

                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-max-price" className="form-label font-medium dark:text-white">Room Type:</label>
                                        <div className="filter-search-form relative mt-2">
                                            <AiOutlineStar className="icons"/>
                                            <Select styles={customStyles} className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={rateOptions} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <PoyikaImages allData={true} pagination={true}/>
            </section>
            <div className="floating_btn">
                <a target="_blank" href="https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details">
                    <div className="contact_icon">
                        <img style={{height:40, width:40}} src={Whatsapp1} alt="WhatsApp" />
                    </div>
              </a>
            </div>
            <div className="button-container">
        {/* Call Button */}
        <button className="call-button" onClick={makeCall}>
          {/* Add Call icon component or image here */}
          <img src={Call} alt="Call" className="whatsapp-icon" />
          Call Now
        </button>

        {/* WhatsApp Button */}
        <button className="whatsapp-button" onClick={openWhatsApp}>
          {/* WhatsApp icon image */}
          <img src={Whatsapp} alt="WhatsApp" className="whatsapp-icon" />
          WhatsApp
        </button>
      </div>
            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
