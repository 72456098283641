import React, { useState } from 'react'
import Navbar from '../components/navbar'
import Team from '../components/team'
import Blog from '../components/blog'
import Footer from '../components/footer'
import about from '../assets/images/about-1.png';
import Switcher from '../components/switcher'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import IMG from '../assets/images/last-image.jpg';
import Main from '../assets/images/main4.png';
import Main1 from '../assets/images/main-5.png';
import MAIN2 from '../assets/images/main5.png';
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import {FaRegEnvelope} from "../assets/icons/vander"

export default function About() {
    const [isOpen, setOpen] = useState(false)
    const dosha = 'https://c8.alamy.com/comp/2HJ6JX9/paper-masala-dosa-south-indian-traditional-meal-served-with-sambhar-and-coconut-chutney-traditional-south-indian-food-2HJ6JX9.jpg';
    const puttu = 'https://cdn4.vectorstock.com/i/1000x1000/56/13/kerala-or-south-indian-breakfast-puttu-vector-33555613.jpg';
    const idli = 'https://i.pinimg.com/736x/6f/a0/6e/6fa06ea30fb62c3374c802437cc45180.jpg';
    const fishfry = 'https://image.shutterstock.com/image-vector/kerala-food-fish-fry-vector-260nw-1404872657.jpg';
    const sadya = 'https://www.shutterstock.com/image-vector/south-indian-traditional-wedding-food-260nw-2017931834.jpg';
    const aviyal = 'https://www.shutterstock.com/image-photo/homemade-aviyal-mixed-vegetable-curry-260nw-1754948453.jpg';
    const payasam = 'https://www.shutterstock.com/image-photo/closeup-view-semiya-payasam-kheer-260nw-2346235033.jpg';
    const mangorice = 'https://img.freepik.com/premium-vector/mango-sticky-rice-traditional-asian-sweet-food-logo-cartoon-art-illustration_56104-1542.jpg?w=360';
    const prawn = 'https://www.yellowthyme.com/wp-content/uploads/2016/04/Kerala-Shrimp-Curry-33.jpg';
    const fishcurry = 'https://thumbs.dreamstime.com/b/kerala-fish-curry-white-rice-south-indian-sri-lanka-very-popular-red-clay-pot-coastal-area-malaysia-thailand-singapore-198511928.jpg';
    const bedroom1 = 'https://i.pinimg.com/736x/6f/a0/6e/6fa06ea30fb62c3374c802437cc45180.jpg';
    const bedroom2 = 'https://i.pinimg.com/736x/6f/a0/6e/6fa06ea30fb62c3374c802437cc45180.jpg';
    const bedroom3 = 'https://i.pinimg.com/736x/6f/a0/6e/6fa06ea30fb62c3374c802437cc45180.jpg';
    const bedroom16 = 'https://i.pinimg.com/736x/6f/a0/6e/6fa06ea30fb62c3374c802437cc45180.jpg';
    const bedroom17 = 'https://i.pinimg.com/736x/6f/a0/6e/6fa06ea30fb62c3374c802437cc45180.jpg';
    const bedroom18 = 'https://i.pinimg.com/736x/6f/a0/6e/6fa06ea30fb62c3374c802437cc45180.jpg';
    const bedroom19 = 'https://i.pinimg.com/736x/6f/a0/6e/6fa06ea30fb62c3374c802437cc45180.jpg';
    const bedroom20 = 'https://i.pinimg.com/736x/6f/a0/6e/6fa06ea30fb62c3374c802437cc45180.jpg';


    return (
        <>

            <section className="relative md:py-24 py-16" style={{marginTop:'-90px'}}>
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7">
                            <div className="lg:ms-5">
                                <h2 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">Poyika Houseboat Food Section</h2>
                                <p className="text-slate-400 max-w-xl flex items-center">
                                    <img
                                        src={dosha}
                                        className="h-20 w-20 rounded-full p-3"
                                        alt=""
                                    />
                                    Dosha And Sambar
                                </p>
                                <p className="text-slate-400 max-w-xl flex items-center">
                                    <img
                                        src={puttu}
                                        className="h-20 w-20 rounded-full p-3"
                                        alt=""
                                    />
                                    Puttu with Kadala Curry
                                </p>
                                <p className="text-slate-400 max-w-xl flex items-center">
                                    <img
                                        src={idli}
                                        className="h-20 w-20 rounded-full p-3"
                                        alt=""
                                    />
                                    Idli And Chutney
                                </p>
                            </div>
                        </div>

                        <div className="lg:col-span-5">
                            <div className="relative">
                                <div className="absolute inset-0 bg-gradient-to-r from-violet-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div>
                                <img src={Main} className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16" style={{marginTop:'-70px'}}>
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <div className="absolute inset-0 bg-gradient-to-r from-violet-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div>
                                <img src={MAIN2} className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                                
                            </div>
                        </div>

                        <div className="lg:col-span-7">
                            <div className="lg:ms-5">
                                <h2 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">Poyika Houseboat Food Section</h2>
                                <p className="text-slate-400 max-w-xl flex items-center">
                                      <img
                                        src={fishcurry}
                                        className="h-20 w-20 rounded-full p-3"
                                        alt=""
                                      />
                                        Kerala Fish Curry with Steamed Rice
                                </p>
                                <p className="text-slate-400 max-w-xl flex items-center">
                                      <img
                                        src={prawn}
                                        className="h-20 w-20 rounded-full p-3"
                                        alt=""
                                      />
                                        Prawn Masala

                                </p>
                                <p className="text-slate-400 max-w-xl flex items-center">
                                      <img
                                        src={fishfry}
                                        className="h-20 w-20 rounded-full p-3"
                                        alt=""
                                      />
                                        Fish Fry
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                
            </section>

            <section className="relative md:py-24 py-16" style={{marginTop:'-70px'}}>
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7">
                            <div className="lg:ms-5">
                                <h2 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">Poyika Houseboat Food Section</h2>
                                <h2 className="mb-4 md:text-1xl text-1xl md:leading-snug leading-snug font-semibold">Vegetarian Feast:</h2>
                                <p className="text-slate-400 max-w-xl flex items-center">
                                      <img
                                        src={aviyal}
                                        className="h-20 w-20 rounded-full p-3"
                                        alt=""
                                      />
                                        Avial (Mixed Vegetable Curry)
                                </p>
                                    
                                <p className="text-slate-400 max-w-xl flex items-center">
                                      <img
                                        src={sadya}
                                        className="h-20 w-20 rounded-full p-3"
                                        alt=""
                                      />
                                        Kerala Style Sadhya
                                </p>
                                <br></br>
                                    
                                <h2 className="mb-4 md:text-1xl text-1xl md:leading-snug leading-snug font-semibold">Sweet Endings:</h2>
                                    
                                    
                                <p className="text-slate-400 max-w-xl flex items-center">
                                      <img
                                        src={payasam}
                                        className="h-20 w-20 rounded-full p-3"
                                        alt=""
                                      />
                                        Kerala Style Payasam
                                </p>
                                    
                                <p className="text-slate-400 max-w-xl flex items-center">
                                      <img
                                        src={mangorice}
                                        className="h-20 w-20 rounded-full p-3"
                                        alt=""
                                      />
                                        Mango Sticky Rice
                                </p>
                            </div>
                        </div>

                        <div className="lg:col-span-5">
                            <div className="relative">
                                <div className="absolute inset-0 bg-gradient-to-r from-violet-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div>
                                <img src={Main1} className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid lg:px-10 md:px-3 relative overflow-hidden" style={{marginTop:'50px',marginBottom:'-20px'}}>
                    <div className="lg:py-24 py-[74px] md:rounded-lg shadow dark:shadow-gray-800 bg-violet-700/10 dark:bg-violet-600/20" style={{backgroundImage:`url(${IMG})`,backgroundSize: 'cover', backgroundPosition: 'center',}}>
                        <div className="container" >
                            <div className="grid grid-cols-1 text-center" >               
                               <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-4xl mb-18">‎ </h1>
                               <div className="mt-6">
                                   <Link to="/" className="">‎ </Link>
                               </div>
                          </div>
                      </div>
                    </div>
                </div>
            </section>

            


        </>
    )
}
