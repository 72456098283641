import React, { useEffect,useState } from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'

import contact from '../assets/images/contact.svg';
import Switcher from '../components/switcher';
import { Link } from 'react-router-dom';
import Whatsapp from '../assets/images/whatsapp.png';
import Call from '../assets/images/whatsapp.png';
import Whatsapp1 from '../assets/images/whatsapp-1.png';
import {FaRegEnvelope, BsTelephone, FiMapPin} from "../assets/icons/vander"

export default function Contact() {
    const [currentButton, setCurrentButton] = useState(0);
    useEffect(() => {
        console.log(currentButton);
        const intervalId = setInterval(() => {
          if (currentButton === 0) {
            document.querySelector('.call-button').style.animation = 'shake 0.4s';
          } else {
            document.querySelector('.whatsapp-button').style.animation = 'shake 0.4s';
          }
    
          setTimeout(() => {
            if (currentButton === 0) {
              document.querySelector('.call-button').style.animation = '';
              setCurrentButton(1); // Switch to the WhatsApp button
            } else {
              document.querySelector('.whatsapp-button').style.animation = '';
              setCurrentButton(0)
            }
          }, currentButton === 0 ? 500 : 500); // 4 seconds for call button, 0 seconds for WhatsApp button
        }, 500); // Faster interval
    
        return () => clearInterval(intervalId);
      }, [currentButton]);
    
      const makeCall = () => {
        // Add your call functionality here
        console.log('Making a call...');
        window.location.href = 'tel:+09446771328';
      };
    
      const openWhatsApp = () => {
        // Add your WhatsApp functionality here
        console.log('Opening WhatsApp...');
        window.location.href = "https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details";
      };
    
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg7.jpg')] bg-bottom bg-no-repeat bg-cover bg-center p-40">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        {/* <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Contact Us</h3> */}
                        <h3 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span className="bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text">Contact </span> Us</h3>

                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <BsTelephone className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title text-xl font-semibold">Phone</h5>
                                <p className="text-slate-400 mt-3">Contact Us On Any Time</p>

                                <div className="mt-5">
                                    <Link to="tel:+09446771328" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">+09446771328</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <FaRegEnvelope className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title text-xl font-semibold">Email</h5>
                                <p className="text-slate-400 mt-3">For Booking Contact Us Through This Email</p>

                                <div className="mt-5">
                                    <Link to="mailto:bookings@poyika.com" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out" style={{color:'black'}}>bookings@poyika.com</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <FiMapPin className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title text-xl font-semibold">Location</h5>
                                <p className="text-slate-400 mt-3">Poyika House Boat Service, <br /> Kottayam, Kumarakoom Kerala-India</p>

                                {/* <div className="mt-5">
                                    <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                        data-type="iframe" className="video-play-icon read-more lightbox btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">View on Google map</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container md:mt-24 mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        {/* <div className="lg:col-span-7 md:col-span-6 order-1 md:order-2">
                            <img src={contact} alt="" />
                        </div> */}

                        <div className="lg:col-span-12 md:col-span-12 mt-12 md:mt-0 order-2 md:order-1">
                            <div className="lg:me-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                    <h3 className="mb-6 text-2xl leading-snug font-medium">For More Details Contact Us !</h3>

                                    <form
                                    //  method="post" 
                                     name="myForm" id="myForm">
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                    <input id="name" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-violet-600 dark:text-violet-600 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Name :" style={{color:'black'}}/>
                                                </div>
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                    <input id="email" type="email" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-violet-600 dark:text-violet-600 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Email :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                    <input id="subject" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-violet-600 dark:text-violet-600 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Subject :" />
                                                </div>
                                            </div>

                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                    <textarea id="comments" className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:bg-violet-600 dark:text-violet-600 rounded-2xl outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Message :"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full justify-center flex items-center">Send Message</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="floating_btn">
                <a target="_blank" href="https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details">
                    <div className="contact_icon">
                        <img style={{height:40, width:40}} src={Whatsapp1} alt="WhatsApp" />
                    </div>
              </a>
            </div>

            <div className="button-container">
        {/* Call Button */}
        <button className="call-button" onClick={makeCall}>
          {/* Add Call icon component or image here */}
          <img src={Call} alt="Call" className="whatsapp-icon" />
          Call Now
        </button>

        {/* WhatsApp Button */}
        <button className="whatsapp-button" onClick={openWhatsApp}>
          {/* WhatsApp icon image */}
          <img src={Whatsapp} alt="WhatsApp" className="whatsapp-icon" />
          WhatsApp
        </button>
      </div>

            {/* <div className="container-fluid relative">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe title="iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{ border: 0 }} className="w-full h-[500px]" allowFullScreen></iframe>
                    </div>
                </div>
            </div> */}

            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
