import React, { useEffect,useState } from 'react'
import Navbar from '../components/navbar'
import Team from '../components/team'
import Blog from '../components/blog'
import Footer from '../components/footer'
import about from '../assets/images/about-1.png';
import Switcher from '../components/switcher'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import Whatsapp from '../assets/images/whatsapp.png';
import Whatsapp1 from '../assets/images/whatsapp-1.png';
import Call from '../assets/images/whatsapp.png';
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import {FaRegEnvelope} from "../assets/icons/vander"

export default function About() {
    const [isOpen, setOpen] = useState(false)
    const [currentButton, setCurrentButton] = useState(0);
    useEffect(() => {
        console.log(currentButton);
        const intervalId = setInterval(() => {
          if (currentButton === 0) {
            document.querySelector('.call-button').style.animation = 'shake 0.4s';
          } else {
            document.querySelector('.whatsapp-button').style.animation = 'shake 0.4s';
          }
    
          setTimeout(() => {
            if (currentButton === 0) {
              document.querySelector('.call-button').style.animation = '';
              setCurrentButton(1); // Switch to the WhatsApp button
            } else {
              document.querySelector('.whatsapp-button').style.animation = '';
              setCurrentButton(0)
            }
          }, currentButton === 0 ? 500 : 500); // 4 seconds for call button, 0 seconds for WhatsApp button
        }, 500); // Faster interval
    
        return () => clearInterval(intervalId);
      }, [currentButton]);
    
      const makeCall = () => {
        // Add your call functionality here
        console.log('Making a call...');
        window.location.href = 'tel:+09446771328';
      };
    
      const openWhatsApp = () => {
        // Add your WhatsApp functionality here
        console.log('Opening WhatsApp...');
        window.location.href = "https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details";
      };
    
    

    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg8.jpg')] bg-bottom bg-no-repeat bg-cover bg-center p-40">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        {/* <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">About Us</h3> */}
                        <h3 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span className="bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text">About </span> Us</h3>

                    </div>
                </div>

                {/* <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Giglink</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">About Us</li>
                    </ul>
                </div> */}
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <div className="absolute inset-0 bg-gradient-to-r from-violet-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div>
                                <img src={about} className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                                {/* <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                    <Link to="#" onClick={() => setOpen(true)} data-id="S_CGed6E610" className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-violet-600">
                                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />

                        <div className="lg:col-span-7">
                            <div className="lg:ms-5">
                                {/* <span className="text-violet-600 font-medium">Creative Vision & Mission</span> */}
                                <h2 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">Poyika Houseboat<br /> Service</h2>

                                <p className="text-slate-400 max-w-xl">Experience the grandeur of the vast Vembanad lake with the Poyika houseboats, that offers the guests all the luxuries and comforts of a home. These houseboats combines in all the modern luxuries with comforts. These houseboat comes with many provisions like living rooms, dining rooms, sun decks, bedrooms, well stocked kitchen, uninterrupted water supply and so on. The crew members of these boats are very well trained and are always ready to help their guests. Each boat consists of three crew members who are a driver, assistant and a cook.</p>
                                {/* <p className="text-slate-400 max-w-xl mt-4">It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. This might also explain why one can now find slightly different versions.</p> */}

                                <div className="mt-6">
                                    <Link to="/contact" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2 inline-flex items-center"><FaRegEnvelope style={{color:'white'}} className='me-1'/> Contact us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="floating_btn">
                <a target="_blank" href="https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details">
                    <div className="contact_icon">
                        <img style={{height:40, width:40}} src={Whatsapp1} alt="WhatsApp" />
                    </div>
              </a>
            </div>

                <div className="button-container">
        {/* Call Button */}
        <button className="call-button" onClick={makeCall}>
          {/* Add Call icon component or image here */}
          <img src={Call} alt="Call" className="whatsapp-icon" />
          Call Now
        </button>

        {/* WhatsApp Button */}
        <button className="whatsapp-button" onClick={openWhatsApp}>
          {/* WhatsApp icon image */}
          <img src={Whatsapp} alt="WhatsApp" className="whatsapp-icon" />
          WhatsApp
        </button>
      </div>
                {/* <div className="container lg:mt-24 mt-16 lg:pt-24 pt-16">
                    <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.png')] bg-no-repeat bg-center"></div>
                    <div className="relative grid grid-cols-1 pb-8 text-center z-1">
                        <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold text-black dark:text-white">Trusted by more than 20K users</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">We are a huge marketplace dedicated to connecting great artists of all Giglink with their fans and unique token collectors!</p>
                    </div>

                    <div className="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px] z-1">
                        <div className="counter-box text-center">
                            <h1 className="lg:text-5xl text-4xl font-semibold mb-2">$<span className="counter-value" data-target="40">3</span>M</h1>
                            <h5 className="counter-head text-lg font-medium text-slate-400">Trading volume</h5>
                        </div>

                        <div className="counter-box text-center">
                            <h1 className="lg:text-5xl text-4xl font-semibold mb-2"><span className="counter-value" data-target="200">2</span>+</h1>
                            <h5 className="counter-head text-lg font-medium text-slate-400">NFTs created</h5>
                        </div>

                        <div className="counter-box text-center">
                            <h1 className="lg:text-5xl text-4xl font-semibold mb-2"><span className="counter-value" data-target="235">0</span>K</h1>
                            <h5 className="counter-head text-lg font-medium text-slate-400">Total users</h5>
                        </div>
                    </div>
                </div> */}

                {/* <Team />
                <Blog /> */}
            </section>
            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
