import React, { useState,useEffect } from 'react'
import metamask from '../assets/images/wallet/metamask.png';
import alpha from '../assets/images/wallet/alpha.png';
import binance from '../assets/images/wallet/binance.png';
import bitski from '../assets/images/wallet/bitski.png';
import coinbase from '../assets/images/wallet/coinbase.png';
import enjin from '../assets/images/wallet/enjin.png';
import formatic from '../assets/images/wallet/formatic.png';
import pionex from '../assets/images/wallet/pionex.png';
import torus from '../assets/images/wallet/torus.png';
import trustwallet from '../assets/images/wallet/trustwallet.png';
import connect from '../assets/images/wallet/wallet-connect.png';
import Navbar from '../components/navbar'
import Footer from '../components/footer';
import Switcher from '../components/switcher';
import Whatsapp from '../assets/images/whatsapp.png';
import Whatsapp1 from '../assets/images/whatsapp-1.png';
import Call from '../assets/images/whatsapp.png';
import { Link } from 'react-router-dom';
import {MdOutlineArrowForward} from "../assets/icons/vander"

export default function Wallet() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const [currentButton, setCurrentButton] = useState(0);
    useEffect(() => {
        console.log(currentButton);
        const intervalId = setInterval(() => {
          if (currentButton === 0) {
            document.querySelector('.call-button').style.animation = 'shake 0.4s';
          } else {
            document.querySelector('.whatsapp-button').style.animation = 'shake 0.4s';
          }
    
          setTimeout(() => {
            if (currentButton === 0) {
              document.querySelector('.call-button').style.animation = '';
              setCurrentButton(1); // Switch to the WhatsApp button
            } else {
              document.querySelector('.whatsapp-button').style.animation = '';
              setCurrentButton(0)
            }
          }, currentButton === 0 ? 500 : 500); // 4 seconds for call button, 0 seconds for WhatsApp button
        }, 500); // Faster interval
    
        return () => clearInterval(intervalId);
      }, [currentButton]);
    
      const makeCall = () => {
        // Add your call functionality here
        console.log('Making a call...');
        window.location.href = 'tel:+09446771328';
      };
    
      const openWhatsApp = () => {
        // Add your WhatsApp functionality here
        console.log('Opening WhatsApp...');
        window.location.href = "https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details";
      };
    

    const bedroom1 = 'https://keralahouseboatrentals.com/theme/images/boat3.png';
    const bedroom2 = 'https://cdn.dribbble.com/users/5364494/screenshots/16417123/media/542d70972fe71df093d02d85406718e3.jpg?resize=400x0';
    const bedroom3 = 'https://static.vecteezy.com/system/resources/thumbnails/000/158/521/small_2x/boat-in-kerala-vector-illustration.jpg';
    const bedroom4 = 'https://u7.uidownload.com/vector/7/426/vector-kerala-houseboat-vector-background-illustration-ai-svg-thumbnail.jpg';
    const bedroom5 = 'https://static.vecteezy.com/system/resources/thumbnails/000/158/530/small/kerala-houseboat-vector-background-illustration.jpg';
    const bedroom6 = 'https://www.shutterstock.com/image-vector/onam-festival-celebration-background-coconut-260nw-2173243619.jpg';
    const walletData = [
        {
            title: '01 Bedroom Delux',
            image: bedroom1,
            p: 'Offering a perfect privacy for couples especially, this one bedroom houseboat is the ultimate option of luxury. These boats offer you the value for money. Each and every aspect is specifically designed.'
        },
        {
            title: '02 Bedroom Delux',
            image: bedroom2,
            p: 'These 2 bedrooms houseboats comes in two designs that are completely different yet same. A stay in these houseboats is sure to make your holidays a really special and memorable one as well.'
        },
        {
            title: '03 Bedroom Delux',
            image: bedroom3,
            p: 'These houseboats are best suited for large families and groups. These boats can also be rented out for special occasions or any functions. Three bedrooms are what is offered in these boats.'
        },
        {
            title: 'Smooth Check-in',
            image: bedroom4,
            p: 'Let our representative take care of the formal procedures while you sit back and relax.'
        },
        {
            title: 'Experienced Houseboat Crew',
            image: bedroom5,
            p: 'Our houseboat staffs will make sure you are comfortable and enjoy your every moment of cruise.'
        },
        {
            title: 'Customer Support',
            image: bedroom6,
            p: 'In case of any inconvenience, please call our customer care representative number we provide prior to your cruise'
        },
        // {
        //     title: 'Formatic',
        //     image: formatic
        // },
        // {
        //     title: 'Pionex',
        //     image: pionex
        // },
        // {
        //     title: 'Torus',
        //     image: torus
        // },
        // {
        //     title: 'Trustwallet',
        //     image: trustwallet
        // },
        // {
        //     title: 'Wallet Connect',
        //     image: connect
        // },

    ]
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg9.jpg')] bg-bottom bg-no-repeat bg-cover bg-center p-40">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        
                    <h3 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span className="bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text">Our </span> Services</h3>
                        {/* <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Wallet Connect</h3> */}
                    </div>
                </div>

                {/* <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Giglink</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Wallet</li>
                    </ul>
                </div> */}
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        {
                            walletData.map((ele, index) => (
                                <div key={index} className="group relative rounded-xl bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 p-6 text-center mt-10 hover:shadow-md dark:hover:shadow-gray-800 transition-all duration-500">
                                    <div className="relative -mt-16">
                                        <img src={ele.image} className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3" alt="" />
                                        <h5 className="text-lg font-semibold mt-5">{ele.title}</h5>
                                        <p className="text-slate-400 mt-3">{ele.p}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <div className="floating_btn">
                <a target="_blank" href="https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details">
                    <div className="contact_icon">
                        <img style={{height:40, width:40}} src={Whatsapp1} alt="WhatsApp" />
                    </div>
              </a>
            </div>
            <div className="button-container">
        {/* Call Button */}
        <button className="call-button" onClick={makeCall}>
          {/* Add Call icon component or image here */}
          <img src={Call} alt="Call" className="whatsapp-icon" />
          Call Now
        </button>

        {/* WhatsApp Button */}
        <button className="whatsapp-button" onClick={openWhatsApp}>
          {/* WhatsApp icon image */}
          <img src={Whatsapp} alt="WhatsApp" className="whatsapp-icon" />
          WhatsApp
        </button>
      </div>
            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
