import React, { useState,useEffect } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Select from 'react-select'
import DiscoverItems from '../../components/discover-items';
import Switcher from '../../components/switcher';
import PoyikaImages from '../../components/poyika-images';
import ImageSlider from '../index/imageslider';
import FoodSection from '../../pages/food-section';
import Whatsapp from '../../assets/images/whatsapp.png';
import Whatsapp1 from '../../assets/images/whatsapp-1.png';
import Call from '../../assets/images/call.png';
import { LuClock, LuSearch, AiOutlineAppstore, AiOutlineStar, AiOutlineWhatsApp, AiOutlinePhone } from "../../assets/icons/vander";
const options = [
    { value: 'Art', label: 'Art' },
    { value: 'Domain', label: 'Domain Names' },
    { value: 'Music', label: 'Music' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Trading', label: 'Trading Cards' },
    { value: 'Virtual', label: 'Virtual World' },
];

const buyOptions = [
    { value: 'now', label: 'Buy Now' },
    { value: 'auctions', label: 'Auctions' },
    { value: 'offers', label: 'Offers' }
]
const rateOptions = [
    { value: 'top', label: 'Top Rated' },
    { value: 'low', label: 'Lower Rated' }
]

export default function ExploreOne() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);

    const [currentButton, setCurrentButton] = useState(0);

  useEffect(() => {
    console.log(currentButton);
    const intervalId = setInterval(() => {
      if (currentButton === 0) {
        document.querySelector('.call-button').style.animation = 'shake 0.4s';
      } else {
        document.querySelector('.whatsapp-button').style.animation = 'shake 0.4s';
      }

      setTimeout(() => {
        if (currentButton === 0) {
          document.querySelector('.call-button').style.animation = '';
          setCurrentButton(1); // Switch to the WhatsApp button
        } else {
          document.querySelector('.whatsapp-button').style.animation = '';
          setCurrentButton(0)
        }
      }, currentButton === 0 ? 500 : 500); // 4 seconds for call button, 0 seconds for WhatsApp button
    }, 500); // Faster interval

    return () => clearInterval(intervalId);
  }, [currentButton]);

  const makeCall = () => {
    // Add your call functionality here
    console.log('Making a call...');
    window.location.href = 'tel:+09446771328';
  };

  const openWhatsApp = () => {
    // Add your WhatsApp functionality here
    console.log('Opening WhatsApp...');
    window.location.href = "https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details";
  };

    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg11.jpg')] bg-bottom bg-no-repeat bg-cover bg-center">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span className="bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text">Poyika Houseboat </span> Service</h3>
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Kumarakom - Kerala</h3>
                        
                    </div>
                    <div class="button-containerr">
                      <a href="tel:+09446771328" class="button">Call Us Now : +09446771328</a>
                      <a href="https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details" class="button1">WhatsApp Now : 9446771328</a>
                      <a href="mailto:bookings@poyika.com" class="button2">Send Mail To Poyika Housboats</a>
                    </div>
                </div>

            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
                
            </div>

            

            <section className="relative md:pb-24 pb-16 -mt-16">
                <div className="container z-1">
                    {/* <div className="grid grid-cols-1">
                        <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow dark:shadow-gray-700">
                            <div className="registration-form text-dark text-start">
                                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                    <div>
                                        <label className="form-label font-medium dark:text-white">Search:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <LuSearch className="icons"/>
                                            <input name="name" type="text" id="job-keyword" className="form-input w-full filter-input-box bg-gray-50 dark:bg-slate-800 border-0 focus:ring-transparent" placeholder="Search your keaywords" />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-catagory" className="form-label font-medium dark:text-white">Categories:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <AiOutlineAppstore className="icons"/>
                                            <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={options} />

                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-min-price" className="form-label font-medium dark:text-white">Items:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <LuClock className="icons text-base"/>
                                            <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={buyOptions} />

                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-max-price" className="form-label font-medium dark:text-white">Sort By:</label>
                                        <div className="filter-search-form relative mt-2">
                                            <AiOutlineStar className="icons"/>
                                            <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={rateOptions} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> */}
                    
                </div>
                
                
                
                {/* <PoyikaImages allData={true} pagination={true}/> */}
            </section>

            <div style={{padding:'20px'}}>

            <div class="group relative rounded-xl bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 p-6 text-center mt-8 hover:shadow-md dark:hover:shadow-gray-800 transition-all duration-500">
              <div class="relative -mt-16">
                <p class="mx-auto p-3" alt="" />
                <h5 class="text-lg font-semibold mt-10">Poyika houseboats</h5>
                <p class="text-slate-400 mt-5">Poyika houseboats provide an unparalleled experience on the expansive Vembanad lake, offering guests the luxuries and comforts of a home. These houseboats seamlessly blend modern amenities with traditional comforts, featuring living rooms, dining areas, sun decks, bedrooms, and a well-equipped kitchen.</p>
              </div>
            </div>
            </div>
            <div className="floating_btn">
                <a target="_blank" href="https://wa.me/9446771328?text=Hi,%20I'%20am%20looking%20for%20Houseboats.%20%20Please%20send%20me%20the%20details">
                    <div className="contact_icon">
                        <img style={{height:40, width:40}} src={Whatsapp1} alt="WhatsApp" />
                    </div>
              </a>
            </div>

            <div className="button-container">
        {/* Call Button */}
        <button className="call-button" onClick={makeCall}>
          {/* Add Call icon component or image here */}
          <img src={Call} alt="Call" className="whatsapp-icon" />
          Call Now
        </button>

        {/* WhatsApp Button */}
        <button className="whatsapp-button" onClick={openWhatsApp}>
          {/* WhatsApp icon image */}
          <img src={Whatsapp} alt="WhatsApp" className="whatsapp-icon" />
          WhatsApp
        </button> 
      </div>
            <div style={{marginTop:'-30px'}}>
                <ImageSlider/>    
            </div>
            <div style={{marginTop:'70px'}}>
                <FoodSection />
            </div>
            
            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
